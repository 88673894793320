<template>
  <vbt-content-box title="Information">
    <template #toolbar>
      <v-layout style="font-size: 14px; margin-left: -200px;">
        <strong class="mr-2">
          Rule Name:
        </strong>

        <v-flex sm5>
          <v-text-field
            v-model.trim="ruleName"
            class="vbt-hide-form-control-details"
            height="20px"
            dense
          />
        </v-flex>
      </v-layout>
    </template>

    <dsb-rule-form
      creator
      :rule-name="ruleName"
      @submit="createRule"
      @reset:form="resetRuleName"
    />
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { RulesApiService } from '../_services/rules.api.service';

import DsbRuleForm from '../_components/DsbRuleForm';

export default {
  name: 'DsbRuleCreator',

  components: {
    DsbRuleForm,
  },

  data() {
    return {
      ruleName: '',
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  methods: {
    createRule(form) {
      this.$VBlackerTheme.alert.warning({
        text: 'Create rule?',
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.createRule.bind({}, {
          suffix: this.suffix,
          data: form,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: ({ id }) => {
          this.$VBlackerTheme.notification.success('Rule successfully created');

          this.$router.push({
            name: 'ruleEdit',
            params: { id },
            query: {
              suffix: this.suffix,
            },
          });
        },
      }));
    },

    resetRuleName() {
      this.ruleName = '';
    },

    wrapToLoadingFn,
  },
};
</script>
